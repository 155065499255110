<template>
  <el-main>
    <div class="centerwrap">
      <div class="centerbox">
        <!-- <h2 class="gobackpage">
          <span @click="prev">
            <i class="iconlujingbeifen1 iconfont"></i>返回
          </span>
        </h2> -->
        <el-form label-width="80px" ref="columnformRef" :model="columnform" :rules="columnformRul">
          <el-form-item>
            <h2 class="titleh2" v-text="columnform.Special ||'专栏名称'"></h2>
          </el-form-item>
          <el-form-item prop="ownspecial">
            <el-col :span="11">
              <el-select v-model="columnform.ownspecial" placeholder="请选择专栏" style="width:100%">
                <el-option
                  v-for="(item,index) in ownspecial"
                  :label="item.Specail"
                  :value="item.Sid"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="11">
              <router-link tag="span" :to="{name:'columnInfo'}" class="addclumn">添加专栏</router-link>
            </el-col>
          </el-form-item>
          <el-form-item prop="article">
            <el-col :span="11">
              <el-input v-model="columnform.article" placeholder="请输入标题"></el-input>
            </el-col>
            <el-col :span="11" class="tacr">
              <span @click="handelChangeMavon">
                <i class="iconfont iconqiehuan"></i>
                切换到 {{columnform.Editor==1? 'markdown' :'tinymce'}}
              </span>
            </el-col>
          </el-form-item>
          <el-form-item>
            <el-col :span="22">
              <tinymce-editor
                v-model="columnform.Content"
                :disabled="disabled"
                ref="editor"
                :theight="curHeight"
                v-if="columnform.Editor==1"
              ></tinymce-editor>
              <mavon-editor
                :value="Markdown"
                ref="meditor"
                @input="Markdown=$event"
                v-if="columnform.Editor==2"
              ></mavon-editor>
            </el-col>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              v-loading="btnLoading"
              class="savebtn"
              @click="handelSaveBook('columnformRef')"
            >保 存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-main>
</template>
<script>
import {
  specialArticleRequest,
  saveSpecialArticleRequest,
  specialOwnRequest
} from "@/api/project.js";

import TinymceEditor from "@/components/tinymceEditor"; //编辑器
import MavonEditor from "@/components/mavonEditor"; //编辑器
import { TurndownService } from "@/assets/js/turndown.js";
import moment from "moment";
export default {
  components: {
    TinymceEditor, //编辑器
    MavonEditor //编辑器
  },
  data() {
    return {
      isDel: true,
      isEditor: false,
      disabled: false,
      inittac: {
        height: "500px"
      },
      Editor: 0, //文章默认编辑器
      editorIndex: 0, //整本书默认编辑器
      Markdown: "",
      curHeight: "300px",
      listData: [],
      commentinpError: false,
      active: -1,
      replayinp: "",
      loading: false,
      totalCount: 0,
      pageCount: 0,
      page: 0,
      notitle: false,
      ownspecial: [],
      columnform: {
        ownspecial: "",
        article: "",
        Special: "",
        Content: "",
        Editor: 1
      },
      btnLoading: false,
      columnformRul: {
        article: [
          { required: true, message: "请输入标题", trigger: "blur" },
          { min: 2, message: "长度在2个字符", trigger: "blur" }
        ],
        ownspecial: [
          { required: true, message: "请选择专栏", trigger: "change" }
        ]
      }
    };
  },
  created() {
    if (this.artid) this.getSpecialArticle();
    if (this.sid) {   
      this.columnform.ownspecial = this.sid;
    }
    this.getSpecialOwn();
    // this.setEditorHeight();
  },
  methods: {
    showtext(e) {
      e.currentTarget.className = "showtext";
    },
    hidetext(e) {
      e.currentTarget.className = "";
    },
    async getSpecialOwn() {
      try {
        var _this = this;
        const result = await specialOwnRequest();
        if (result.status == 200) {
          if (result.data) {
            this.ownspecial = result.data.ownspecial;
          }
        }
      } catch (err) {}
    },
    async getSpecialArticle(secid) {
      try {
        var _this = this;
        const result = await specialArticleRequest({
          artid: this.artid
        });
        if (result.status == 200) {
          if (result.data) {
            this.article = result.data.article;
            this.columnform.ownspecial = result.data.article.Sid;
            this.columnform.Special = result.data.article.Special;
            this.columnform.article = result.data.article.Article;
            this.columnform.Content = result.data.article.Content;
            this.Markdown = result.data.article.Markdown;
          }
        }
      } catch (err) {}
    },
    handelTab(index, a) {
      this[a] = index;
    },
    TmacChangeMavon(editor, item) {
      console.log(editor, item);
      if (item) this.columnform[item] = editor;
      // editor=2 是  markdown  既markdown 变成 tinymac格式
      // editor=1 是  tinymce   tinymac 变成 markdown
      var _this = this;
      if (editor === 1) {
        // 既markdown 变成 tinymac格式
        var marked = require("marked");
        var rendererMD = new marked.Renderer();
        marked.setOptions({
          renderer: rendererMD,
          gfm: true,
          tables: true,
          breaks: false,
          pedantic: false,
          sanitize: false,
          smartLists: true,
          smartypants: false
        }); //基本设置
        _this.columnform.Content = marked(_this.Markdown);
      } else {
        //tinymac 变成 markdown
        var turndownService = new TurndownService(); //Tmac变成Mavon格式
        _this.Markdown = turndownService.turndown(_this.columnform.Content);
      }
    },
    handelChangeMavon() {
      var _this = this;
      this.$confirm("切换编辑器后，部分样式可能会丢失, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          _this.TmacChangeMavon(
            _this.columnform.Editor === 2 ? 1 : 2,
            "Editor"
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消切换"
          });
        });
    },
    handelSaveBook(formName) {
      this.$refs[formName].validate(valid => {
        this.btnLoading = true;
        try {
          if (!valid) {
            this.btnLoading = false;
            return;
          }
          this.saveBook();
        } catch (err) {
          this.$message({
            showClose: true,
            message: "保存失败",
            type: "error"
          });
          this.btnLoading = false;
          console.log(err);
        }
      });
    },
    async saveBook() {
      try {
        var oData = {};
        if (this.Editor === 2) {
          //markdown
          this.TmacChangeMavon(1);
        }
        //markdown
        oData = {
          artid: this.artid,
          sid: this.columnform.ownspecial,
          article: this.columnform.article,
          content: this.columnform.Content,
          editor: this.columnform.Editor,
          markdown: this.columnform.Editor === 2 ? this.Markdown : ""
        };

        const result = await saveSpecialArticleRequest(oData);
        if (result.status == 200) {
          this.$message({
            message: "恭喜你，保存成功",
            type: "success"
          });
          this.btnLoading = false;
          this.$router.push({
            name: "specialColumnDetail",
            params: { artid: result.data.artid }
          });
        } else {
          this.$message({
            message: `${result.message}`,
            type: "warning"
          });
          this.btnLoading = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    setEditorHeight() {
      var h =
        document.documentElement.clientHeight || document.body.clientHeight;
      this.curHeight = h - 61; //减去页面上固定高度height
    },
    prev(){
      this.$router.go(-1)
    }
  },
  filters: {
    dateformat(el) {
      return moment(el).format("YYYY/MM/DD");
    }
  },
  computed: {
    sid() {
      this.columnform.ownspecial = this.$route.query.sid;
      return this.$route.query.sid;
    },
    artid() {
      return this.$route.query.artid;
    }
  }
};
</script>
<style  lang="less" scoped>
.textbox{
    /deep/p{
    margin-top: 1em;
    margin-bottom: 1em;
  }
  /deep/ul,  /deep/li  {
  list-style: inherit;
}
/deep/h1, h2, h3, h4, h5, h6 {
  font-weight: inherit;
}
/deep/em, /deep/i {
font-style:italic!important;
}
/deep/ol{
  display: block;
      list-style: decimal;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px
}
}
.centerwrap {
  position: relative;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width:1200px;
  margin:30px auto;
}

.is-error {
  .errorp {
    position: absolute;
    color: #f56c6c;
    font-size: 12px;
  }
}
.el-form {
  padding: 50px 0;
}
.tacr {
  text-align: right;
  .iconqiehuan {
    color: #11adcf;
    margin-right: 5px;
  }
  span {
    cursor: pointer;
  }
}
.addclumn {
  color: #3377ff;
  margin-left: 15px;
  cursor: pointer;
  text-decoration: underline;
}
.gobackpage {
  padding: 18px 0 0 15px;
  font-size: 16px;
  color: #000000;
  line-height: 20px;
  height: 38px;
  box-sizing: border-box;
  cursor: pointer;
  i {
    margin-right: 10px;
  }
}
</style>